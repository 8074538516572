

#head {
  font-weight: normal;
  font-size: 60px;
  margin: 300px 0;
}

#contact {
  font-size: 20px;


}

@media only screen
and (max-device-width : 800px)
and (orientation : portrait) {
  #head {
    margin: 150px 0 300px;
    font-size: 40px;
  }
}

@media only screen
and (max-device-width : 800px)
and (orientation : landscape) {
  #head {
    margin: 100px 0 100px;
  }
}
