body {
  font-family: 'Arapey', serif;
  color: #444;
  text-align: center;
}


#contact a {
  background-color: #CDD;
  text-decoration: none;
  color: #111;
  padding: 5px;

  &:hover {
    background-color: #DEE;
  }

  &:hover, &:visited, &:focus {
    text-decoration: none;
    color: #111;
  }
}
